<div class="footer-content">
    <section class="container">
        <div class="col about-col">
            <div class="title" trans>About</div>
            <div class="content">{{siteDescription()}}</div>
        </div>

        <custom-menu class="col" position="footer-1" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-2" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-3" [showTitle]="true"></custom-menu>
    </section>
</div>

<!--<div class="footer-bar">
    <span trans>Copyright</span> &copy; {{year()}} {{siteName()}}
</div>-->

<div class="footer-bar clearfix">
  <div class="footer-copyright" id="copyright">Copyright &copy; {{year()}} <span class="brand">HowDoesTheMovieEnd</span>
    <a href="https://www.facebook.com/howdoesthemovieend/" target="_blank"><img class="footer-logo" src="images/icon-fb-grey.png" /></a>
    <a href="https://twitter.com/Movie_Endings" target="_blank"><img class="footer-logo" src="images/icon-twitter-grey.png" /></a>
  </div>
  <ul class="footer-nav list-unstyled list-inline" id="legal">
    <li><a href="pages/36/privacy-policy">Privacy Policy</a></li> |
    <li><a href="pages/37/tos">Terms of Service</a></li> |
    <li><a href="contact">Contact Us</a></li>
  </ul>
</div>