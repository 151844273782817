<!--<material-navbar menuPosition="custom-page-navbar" *ngIf="!settings.get('vebto.customPages.hideNavbar') && showNavbar"></material-navbar>-->
<material-navbar menuPosition="custom-page-navbar" *ngIf="!settings.get('vebto.customPages.hideNavbar') && showNavbar">
    <search-input class="nav-searchbar"></search-input>
</material-navbar>

<div class="page-body container article-body" [innerHTML]="body$ | async"></div>

<footer>
	<div class="footer-content">
		<section class="container">
			<div class="col about-col">
				<div trans="" class="title">About</div>
				<div class="content">Ever missed the end to a film and wondered how does the movie end? Then this is the website for you! Movie Spoilers, Film Endings and we have a bit of fun too!</div>
			</div>
			<div class="col" _nghost-lup-c86="">
				<div _ngcontent-lup-c86="" class="menu-title ng-star-inserted">Explore</div>
				<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item popular__reviews ng-star-inserted" href="/lists/8"><!----><span _ngcontent-lup-c86="" trans="" class="text">Popular Reviews</span></a>
				<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item recently__added ng-star-inserted" href="/lists/7"><!----><span _ngcontent-lup-c86="" trans="" class="text">Recently Added</span></a>
				<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item review__faqs ng-star-inserted" href="/pages/9/review-notes"><!----><span _ngcontent-lup-c86="" trans="" class="text">Review FAQs</span></a>
				<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item get__involved! ng-star-inserted" href="/pages/2/how-can-i-get-involved"><!----><span _ngcontent-lup-c86="" trans="" class="text">Get Involved!</span></a>
				</div>
				<div class="col" _nghost-lup-c86="">
					<div _ngcontent-lup-c86="" class="menu-title ng-star-inserted">Genres</div>
					<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item action ng-star-inserted" href="/browse?genre=action"><!----><span _ngcontent-lup-c86="" trans="" class="text">Action</span></a>
					<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item comedy ng-star-inserted" href="/browse?genre=comedy"><!----><span _ngcontent-lup-c86="" trans="" class="text">Comedy</span></a>
					<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item drama ng-star-inserted" href="/browse?genre=drama"><!----><span _ngcontent-lup-c86="" trans="" class="text">Drama</span></a>
					<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item crime ng-star-inserted" href="/browse?genre=crime"><!----><span _ngcontent-lup-c86="" trans="" class="text">Crime</span></a>
					<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item adventure ng-star-inserted" href="/browse?genre=adventure"><!----><span _ngcontent-lup-c86="" trans="" class="text">Adventure</span></a>
				</div>
				<div class="col" _nghost-lup-c86=""><div _ngcontent-lup-c86="" class="menu-title ng-star-inserted">Pages</div><!----><!---->
						<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item route-nav-item contact ng-star-inserted" href="/contact"><!----><span _ngcontent-lup-c86="" trans="" class="text">Contact</span></a>
						<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item privacy__policy ng-star-inserted" href="/pages/1/privacy-policy"><!----><span _ngcontent-lup-c86="" trans="" class="text">Privacy Policy</span></a>
						<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item terms_of__use ng-star-inserted" href="/pages/2/terms-of-use"><!----><span _ngcontent-lup-c86="" trans="" class="text">Terms of Use</span></a>
						<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item what__is__this? ng-star-inserted" href="/pages/1/what-is-this"><!----><span _ngcontent-lup-c86="" trans="" class="text">What Is This?</span></a>
						<a _ngcontent-lup-c86="" routerlinkactive="active" class="nav-item custom-menu-item page-nave-item how__does_it__work? ng-star-inserted" href="/pages/5/how-does-it-work"><!----><span _ngcontent-lup-c86="" trans="" class="text">How Does it Work?</span></a>
				</div>
			</section>
		</div>
	<div class="footer-bar clearfix">
		<div id="copyright" class="footer-copyright">Copyright © 2020 <span class="brand">HowDoesTheMovieEnd</span>
			<a href="https://www.facebook.com/howdoesthemovieend/" target="_blank"><img src="images/icon-fb-grey.png" class="footer-logo"></a>
			<a href="https://twitter.com/Movie_Endings" target="_blank"><img src="images/icon-twitter-grey.png" class="footer-logo"></a>
		</div>
			<ul id="legal" class="footer-nav list-unstyled list-inline">
				<li><a href="pages/36/privacy-policy">Privacy Policy</a></li> | <li><a href="pages/37/tos">Terms of Service</a></li> | <li><a href="contact">Contact Us</a></li>
			</ul>
	</div>
</footer>