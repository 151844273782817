<media-item-header [backdrop]="backdropImage$ | async"></media-item-header>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<ng-container *ngIf="title$ | async as title">
    <div class="container">
        <title-primary-details-panel [item]="title"></title-primary-details-panel>
        <current-next-episode-panel *ngIf="title.is_series"></current-next-episode-panel>
        <title-secondary-details-panel [item]="title"></title-secondary-details-panel>

        <mat-tab-group color="accent" animationDuration="0ms">
          <mat-tab [label]="'The Preview' | trans">
              <ng-template matTabContent>
                  <div class="tab-content">
                    <div class="tab-content-left">
                      <h3><strong>{{title.name}}</strong>&nbsp;<small>({{title.year}})</small></h3>
                      <p *ngIf="title?.alttitle"><strong>Alt Title: </strong>
                      {{title.alttitle}}</p>
                      <p *ngIf="title?.production"><strong>Production: </strong>
                      {{title.production}}</p>
                      <p><strong>Our Rating: </strong>
                      {{title.ourrating}}</p>
                      <p><strong>Watchability: </strong>
                      {{title.watchability}}</p>
                      <p *ngIf="title?.contributor"><strong>Contributor: </strong>
                      {{title.contributor}}</p>
                      <p *ngIf="title?.contributorrating"><strong>Contributor Rating: </strong>
                      {{title.contributorrating}}</p>
                      <p *ngIf="title?.views && title?.views > 500"><strong>HDTME Views: </strong>
                      {{title.views}}</p>
                      <p *ngIf="title?.ournotes"><strong>Our Notes:</strong><br>
                      {{title.ournotes}}</p>
                      <div *ngIf="title?.ourcomments"><p><strong>Our Comments:</strong></p>
                      <span [innerHTML]="title.ourcomments"></span></div>
                    </div>
                    <div class="tab-content-right">
                      <media-image [src]="title.images[2].url" mode="landscape" size="large" *ngIf="title.images[2]"></media-image>
                    </div>
                  </div>
              </ng-template>
          </mat-tab>
          <mat-tab [label]="'How Does It End?' | trans" *ngIf="title?.howdoesitend">
              <ng-template matTabContent>
                  <div class="tab-content">
                    <div class="tab-content-left">
                      <span [innerHTML]="title.howdoesitend"></span>
                    </div>
                    <div class="tab-content-right">
                      <media-image [src]="title.images[3].url" mode="landscape" size="large" *ngIf="title.images[3]"></media-image>
                      <media-image [src]="title.images[4].url" mode="landscape" size="large" *ngIf="title.images[4]"></media-image>
                      <media-image [src]="title.images[5].url" mode="landscape" size="large" *ngIf="title.images[5]"></media-image>
                    </div>
                  </div>
              </ng-template>
          </mat-tab>
            <mat-tab [label]="'Cast' | trans">
                <title-cast-panel></title-cast-panel>
            </mat-tab>
            <mat-tab [label]="'Reviews' | trans" *ngIf="currentUser.hasPermission('reviews.view') && settings.get('titles.enable_reviews')">
                <ng-template matTabContent>
                    <review-tab></review-tab>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="'Images' | trans" *ngIf="title.images?.length">
                <ng-template matTabContent>
                    <div class="images-container">
                        <media-image [src]="image.url" [alt]="title.name" mode="landscape" size="large" *ngFor="let image of title.images" (click)="openImageGallery(title.images, image)"></media-image>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="'Videos' | trans" *ngIf="title.show_videos || settings.get('titles.show_videos_panel')">
                <ng-template matTabContent>
                    <title-videos *ngIf="title.show_videos || settings.get('titles.video_panel_mode')"></title-videos>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="'Comments' | trans" *ngIf="title?.usercomments">
                <ng-template matTabContent>
                    <div class="tab-content">
                        <div class="clr" *ngIf="title?.usercomments">
                          <strong>Your Comments:</strong><br>
                          <span class="tab-content-comments" [innerHTML]="title.usercomments"></span>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <ad-host slot="ads.title" class="title-ad-host"></ad-host>
    </div>

    <div class="tagline" *ngIf="title?.tagline">
        <q class="container">{{title.tagline}}</q>
    </div>
</ng-container>

<related-titles-panel></related-titles-panel>
<footer></footer>
