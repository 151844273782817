<slider>
    <media-item-header [transparent]="true"></media-item-header>
</slider>

<section class="home-content home-intro list">
  <div class="container">
  	<h2 class="heading">How Does the Movie End - Movie Endings &amp; Movie Spoilers Review &amp; Ratings Website</h2>
    <media-grid class="full-width"><!----></media-grid>
  	<p><strong>Welcome to our site - Spoiler Alert!</strong></p>

  	<p>Ever missed the end to a film and wondered "<a href="movies/" title="Full list of featured movie spoilers"><strong>how does the movie end</strong></a>"?&nbsp; <br><br>Then this is the website for you!&nbsp; We watch all the poorly-written, directed or acted movies so you don't have to!</p>
  	<p><strong>How Does the Movie End is the definitive movie endings and movie spoilers website directory</strong>. We throw in the mix a ratings and review feature, argument-inducing "best and worst movie ending lists" ... and we like to have a bit of fun too.<br><br>We're a <strong>movie review website with a twist</strong> - we tell you the movie ending and don't hold any punches, so you can decide whether to watch it or give it a miss. Not just packed with spoiler alerts, we encourage comments from members, uploaded reviews, movie news and we even throw in special offers when we find them! You can even find us on Facebook at <a href="https://www.facebook.com/howdoesthemovieend" target="_blank" title="How does the movie end on Facebook"><strong>www.facebook.com/howdoesthemovieend</strong></a>!</p>

  	<p>So, what are you waiting for?! This could be the start of something amazing. It might even be emotional.</p>
  </div>
</section>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<section class="lists">
    <ng-container *ngFor="let list of content$ | async; index as index">
        <div class="list">
            <div class="container">
                <h2>
                    <a [routerLink]="['/lists', list.id]">{{list.name}}</a>
                </h2>
                <p *ngIf="list.description">{{list.description}}</p>
                <media-grid [items]="list.items" class="full-width" [showPlayButton]="settings.get('homepage.show_play_buttons')"></media-grid>
            </div>
        </div>

        <ad-host slot="ads.homepage" *ngIf="index === 0" class="homepage-ad-host"></ad-host>
    </ng-container>
    <no-results-message *ngIf="currentUser.hasPermission('admin') && !(haveLists$ | async)">
        <span primary-text trans>No homepage content yet.</span>
        <span secondary-text trans>Assign some lists to the homepage from <a routerLink="/admin/appearance/homepage" [queryParams]="{panel: 'homepage'}">appearance editor</a>.</span>
    </no-results-message>
</section>

<section class="home-content list">
  <div class="container">
  	<h2 class="heading">What is this crazy site about? - Movie Spoilers!</h2>
    <media-grid class="full-width"><!----></media-grid>
  	<p>Whether you fell asleep in front of the tv, or the movie started too late and you needed to call it a night, or the in-flight movie was cut short, or you were interrupted by a noisy neighbour ... then stick around and we'll answer all those burning questions you'll have!&nbsp; <br><br>Did the hero save the day? Did the bad guy get away with it? Do the cute couple get together at the end? <br><br>Too scared to watch the end of the horror movie, or want to show off to people at work that you know the endings to all the classics (without wasting time to sit down and watch them all)?&nbsp; No problem - just search for the title of the movie and we'll soon tell you!<br><br>Got your own review on an obscure movie that you know will help someone else out there? Then why don't you become a member and submit it to our team for review?&nbsp; In a world filled with hundreds of thousands of movies, <strong>we need your help!</strong> Just click the <strong>"</strong><a href="register" title="Register" rel="nofollow"><strong>Register</strong></a><strong>"</strong> link above to get started!</p>
  	
  	<p>Is there a movie you desperately need to find out what happens at the end? Let us know!&nbsp; Don't agree with any of our comments?&nbsp; Add your own!&nbsp; Each movie review allows comments from our site members.</p>
  	
  	<p>All new - catch up on our <a href="pages/7/top-ten-lists" title="Top 10 Movie Lists"><strong>Top 10 Movie Lists</strong></a> including: <a href="pages/28/38-best-movie-twists" title="Best movie twists list">Best Movie Twists</a>, <a href="pages/31/37-greatest-ever-movie-endings" title="Greatest ever movie endings list">Greatest Ever Movie Endings</a>, <a href="pages/23/39-most-disappointing-movie-endings" title="Most disappointing movie endings list">Most Disappointing Movie Endings</a>, <a href="pages/32/36-over-rated-movies" title="Most over-rated movie list">Most Over-Rated Movies</a>, <a href="pages/14/40-least-most-surprising-movie-endings" title="Least Most Surprising Movie Endings">The Least Most Surprising Movie Endings</a> (!), <a href="pages/33/35-guilty-pleasure-movies" title="Gulty Pleasure Movie List">Guilty Pleasure Movies</a> <strong>AND WAIT! -- </strong><a href="pages/20/42-our-least-popular-movies" title="Our Least Popular Reviews"><strong>Our Least Most Popular Reviews!!!</strong></a> We also have new articles for our members from our sponsors and friends.</p>
  	
  	<p><strong>OUR LAST LIST - THE ONE WE'VE ALL BEEN WAITING FOR!! </strong><a href="pages/21/43-top-10-laugh-out-loud-moments" title="Top 10 Laugh Out Loud Movie Moments"><strong>OUR TOP 10 LAUGH-OUT-LOUD MOVIE MOMENTS!</strong></a><strong> We challenge you not to laugh at these!</strong></p>
  	
  	<p>Want to be a part of something special??&nbsp; Sign-up to the membership side of our site (it's free and doesn't take a minute!) to get involved!</p>
  </div>
</section>

<footer></footer>
